<template>
  <div class="container">
    <div class="heading text-center">
      <h4>Часто задаваемые <span class="!text-secondary">вопросы</span></h4>
    </div>
    <div class="mx-auto lg:w-[730px]">
      <div
        v-for="(faq, i) in queries"
        :key="faq.id"
        class="mt-6 border-0 border-b-2 border-gray/20 bg-transparent"
      >
        <button
          type="button"
          class="relative !flex w-full items-center justify-between gap-2 py-2.5 text-lg font-bold text-black ltr:text-left rtl:text-right dark:text-white"
          @click="accordians === i ? (accordians = null) : (accordians = i)"
        >
          <div>{{ faq.question }}</div>
          <div
            class="grid h-6 w-6 flex-shrink-0 place-content-center rounded-full border-2 border-gray text-gray transition"
            :class="{
              '!border-black !text-black dark:!border-white dark:!text-white':
                accordians === i,
            }"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                v-show="accordians !== i"
                d="M6.09961 0.500977C6.65189 0.500977 7.09961 0.948692 7.09961 1.50098L7.09961 10.501C7.09961 11.0533 6.65189 11.501 6.09961 11.501H5.89961C5.34732 11.501 4.89961 11.0533 4.89961 10.501L4.89961 1.50098C4.89961 0.948692 5.34732 0.500977 5.89961 0.500977H6.09961Z"
                fill="currentColor"
              />
              <path
                d="M0.5 5.90039C0.5 5.34811 0.947715 4.90039 1.5 4.90039H10.5C11.0523 4.90039 11.5 5.34811 11.5 5.90039V6.10039C11.5 6.65268 11.0523 7.10039 10.5 7.10039H1.5C0.947715 7.10039 0.5 6.65268 0.5 6.10039V5.90039Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </button>
        <vue-collapsible :isOpen="accordians === i">
          <div class="lg:w-4/5">
            <p
              class="px-0 pb-5 pt-0 text-sm font-bold leading-[18px] text-gray"
            >
              {{ faq.answer }}
            </p>
          </div>
        </vue-collapsible>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import VueCollapsible from "vue-height-collapsible/vue3";

defineProps({
  showTitle: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: "faq",
  },
  queries: {
    type: Array,
    default: () => {
      return [
        {
          id: 1,
          question: "Какие виды мероприятий вы обслуживаете?",
          answer:
            "Мы обслуживаем различные мероприятия, включая конференции, семинары, свадьбы, корпоративные вечеринки и другие.",
        },

        {
          id: 2,
          question: "Каковы ваши цены на аренду оборудования?",
          answer:
            "Цены на аренду зависят от типа и количества оборудования, продолжительности аренды и местоположения мероприятия. Свяжитесь с нами, чтобы получить бесплатную консультацию и ценовое предложение.",
        },

        {
          id: 3,
          question: "Как заранее нужно забронировать оборудование?",
          answer:
            "Рекомендуем забронировать оборудование заранее, чтобы гарантировать его наличие на нужную дату. Обычно мы принимаем заказы за несколько недель до мероприятия, но мы также можем рассмотреть и более краткосрочные заказы.",
        },

        {
          id: 4,
          question:
            "Какую техническую поддержку вы предоставляете во время мероприятия?",
          answer:
            "Мы предоставляем техническую поддержку во время мероприятия, чтобы обеспечить бесперебойную работу оборудования. Наш опытный персонал будет на месте, чтобы решить любые технические проблемы.",
        },

        {
          id: 5,
          question:
            "Можно ли арендовать оборудование для использования за пределами города?",
          answer:
            "Да, мы можем доставить оборудование в любую точку России. Обратитесь к нам, чтобы узнать о возможности аренды в вашем регионе.",
        },

        {
          id: 7,
          question: "Какие дополнительные услуги вы предоставляете?",
          answer:
            "Мы предоставляем дополнительные услуги, такие как создание мультимедийных презентаций, видеозапись мероприятия, трансляция в Интернет и другие. Обратитесь к нам, чтобы узнать больше.",
        },

        {
          id: 8,
          question: "Как происходит доставка оборудования?",
          answer:
            "Мы доставляем оборудование на место мероприятия и устанавливаем его в соответствии с вашими требованиями. После окончания мероприятия мы забираем оборудование.",
        },

        {
          id: 9,
          question: "Можно ли арендовать оборудование на длительный срок?",
          answer:
            "Да, мы предоставляем аренду оборудования на длительный срок для компаний и организаций, которые нуждаются в постоянном доступе к технике.",
        },

        {
          id: 10,
          question:
            "Как связаться с вами для получения консультации и заказа оборудования?",
          answer:
            "Вы можете связаться с нами по телефону, электронной почте или через форму обратной связи на нашем сайте. Наш менеджер свяжется с вами в ближайшее время, чтобы обсудить детали вашего заказа.",
        },
      ];
    },
  },
});

const accordians: any = ref(0);
</script>

<style>
[data-height-collapsible] {
  transition: height 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
