<template>
  <section class="py-14 lg:py-[100px]">
    <div class="container">
      <div class="heading text-center ltr:lg:text-left rtl:lg:text-right">
        <h4>Этапы работы</h4>
      </div>
      <ul>
        <li class="flex border-t-2 border-black/5 py-10 dark:border-gray-dark lg:items-center">
          <div
            class="text-lg font-extrabold text-secondary ltr:pr-5 rtl:pl-5 sm:pt-0.5 sm:text-xl ltr:sm:pr-12 rtl:sm:pl-12 lg:w-1/6 lg:p-0"
            :data-aos="store.direction === 'rtl' ? 'fade-left' : 'fade-right'" data-aos-duration="1000">
            <span class="text-primary">/</span>01
          </div>
          <div class="lg:w-4/6">
            <h4 class="pb-3 text-xl font-extrabold text-black dark:text-white sm:text-2xl">Заявка </h4>
            <p class="text-lg font-semibold">
              Оставляеете заявку на сайте или звоните нам по телефону: <a :href="`tel:${$phone}`"
                class="inline-block transition  hover:text-secondary">{{ $phone }}</a>
            </p>
          </div>
        </li>
        <li class="flex border-t-2 border-black/5 py-10 dark:border-gray-dark lg:items-center">
          <div
            class="text-lg font-extrabold text-secondary ltr:pr-5 rtl:pl-5 sm:pt-0.5 sm:text-xl ltr:sm:pr-12 rtl:sm:pl-12 lg:w-1/6 lg:p-0"
            :data-aos="store.direction === 'rtl' ? 'fade-left' : 'fade-right'" data-aos-duration="1000">
            <span class="text-primary">/</span>02
          </div>
          <div class="lg:w-4/6">
            <h4 class="pb-3 text-xl font-extrabold text-black dark:text-white sm:text-2xl">Менеджер</h4>
            <p class="text-lg font-semibold">Вам назначается линчый менеджер, который ответ на любые вопросы. И никакой
              воды</p>
          </div>
        </li>
        <li class="flex border-t-2 border-black/5 py-10 dark:border-gray-dark lg:items-center">
          <div
            class="text-lg font-extrabold text-secondary ltr:pr-5 rtl:pl-5 sm:pt-0.5 sm:text-xl ltr:sm:pr-12 rtl:sm:pl-12 lg:w-1/6 lg:p-0"
            :data-aos="store.direction === 'rtl' ? 'fade-left' : 'fade-right'" data-aos-duration="1000">
            <span class="text-primary">/</span>03
          </div>
          <div class="lg:w-4/6">
            <h4 class="pb-3 text-xl font-extrabold text-black dark:text-white sm:text-2xl">Расчёт</h4>
            <p class="text-lg font-semibold">В течение нескольких часов наш менеджер подготовит для вас коммерческое
              предложение или по телефону сориентирует по стоимости</p>
          </div>
        </li>
        <li class="flex border-t-2 border-black/5 pt-10 dark:border-gray-dark lg:items-center">
          <div
            class="text-lg font-extrabold text-secondary ltr:pr-5 rtl:pl-5 sm:pt-0.5 sm:text-xl ltr:sm:pr-12 rtl:sm:pl-12 lg:w-1/6 lg:p-0"
            :data-aos="store.direction === 'rtl' ? 'fade-left' : 'fade-right'" data-aos-duration="1000">
            <span class="text-primary">/</span>04
          </div>
          <div class="lg:w-4/6">
            <h4 class="pb-3 text-xl font-extrabold text-black dark:text-white sm:text-2xl">Договор</h4>
            <p class="text-lg font-semibold">Заключаем с вами договор и начинаем работу</p>
          </div>
        </li>
        <li class="flex border-t-2 border-black/5 pt-10 dark:border-gray-dark lg:items-center">
          <div
            class="text-lg font-extrabold text-secondary ltr:pr-5 rtl:pl-5 sm:pt-0.5 sm:text-xl ltr:sm:pr-12 rtl:sm:pl-12 lg:w-1/6 lg:p-0"
            :data-aos="store.direction === 'rtl' ? 'fade-left' : 'fade-right'" data-aos-duration="1000">
            <span class="text-primary">/</span>05
          </div>
          <div class="lg:w-4/6">
            <h4 class="pb-3 text-xl font-extrabold text-black dark:text-white sm:text-2xl">Сопровождение</h4>
            <p class="text-lg font-semibold">Наш менеджер ответит на ваши вопросы в любое время</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import { useAppStore } from '@/stores/index';
const store = useAppStore();

const config = useRuntimeConfig();
</script>
