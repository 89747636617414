<template>
  <section
    class="py-14 dark:!bg-none lg:py-[100px]"
    style="background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 54.69%)"
  >
    <div class="container">
      <div class="flex flex-col items-center justify-center lg:flex-row lg:justify-between">
        <div class="heading text-center ltr:lg:text-left rtl:lg:text-right">
          <h4>Мы предлагаем</h4>
        </div>
        <!-- <div class="flex items-center justify-end gap-4">
                            <div class="text-sm font-extrabold text-black dark:text-white">View All</div>
                            <button type="button" class="bg-black/5 dark:bg-white/5 w-10 h-10 rounded-full flex items-center justify-center hover:bg-secondary dark:hover:bg-secondary transition">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-black dark:text-white">
                                    <path d="M5.95007 1.2002L1.48924 5.3424C1.06317 5.73803 1.06317 6.41236 1.48924 6.80799L5.95007 10.9502" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                                </svg>
                            </button>
                            <button type="button" class="bg-black/5 dark:bg-white/5 text-p w-10 h-10 rounded-full flex items-center justify-center hover:bg-secondary dark:hover:bg-secondary transition">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-black dark:text-white">
                                    <path d="M1.05005 10.7998L5.51089 6.6576C5.93695 6.26197 5.93695 5.58764 5.51089 5.19201L1.05005 1.0498" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                                </svg>
                            </button>
                        </div> -->
      </div>
      <!-- <div class="mb-7">
         <ul
            class="filters home-filter mt-10 flex gap-8 overflow-x-auto whitespace-nowrap pb-3 font-bold lg:mt-0 lg:gap-10">
            <li class="filter" :class="{ active: activeTab === 'all' }">
              <button type="button" class="transition hover:text-secondary" @click="activeTab = 'all'">All Work</button>
            </li>
            <li class="filter" :class="{ active: activeTab === 'design' }">
              <button type="button" class="transition hover:text-secondary" @click="activeTab = 'design'">Design</button>
            </li>
            <li class="filter" :class="{ active: activeTab === 'website' }">
              <button type="button" class="transition hover:text-secondary"
                @click="activeTab = 'website'">Website</button>
            </li>
            <li class="filter" :class="{ active: activeTab === 'app' }">
              <button type="button" class="transition hover:text-secondary" @click="activeTab = 'app'">Mobile App</button>
            </li>
            <li class="filter" :class="{ active: activeTab === 'web' }">
              <button type="button" class="transition hover:text-secondary" @click="activeTab = 'web'">Web
                Application</button>
            </li>
            <li class="filter" :class="{ active: activeTab === 'ecommerce' }">
              <button type="button" class="transition hover:text-secondary"
                @click="activeTab = 'ecommerce'">Ecommerce</button>
            </li>
          </ul>
      </div> -->
      <div
        v-if="categories && categories.data"
        class="projects grid gap-8 sm:grid-cols-2 lg:grid-cols-3"
      >
        <EquipmentsEquipment
          v-for="category in categories.data"
          :key="category.id"
          :category="category"
        />
      </div>
    </div>
  </section>
</template>


<script setup>
const strapiUrl = useRuntimeConfig().public.strapi.url;

const { data: categories } = await useAsyncData(
  `categories-${Date.now()}`,
  () => $fetch(`${strapiUrl}/api/categories`, {
    params: {
      populate: ['main_image', 'seo'],
      sort: 'ordering'
    },
  })
);
</script>
