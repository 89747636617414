<template>
  <div>
    <div
      class="relative overflow-hidden bg-black pt-[82px] sm:-mx-[250px] sm:rounded-b-[50%] lg:-mx-[150px] lg:pt-[106px]">
      <div class="relative">
        <img src="/assets/images/banner-lefticon.png" alt="banner-lefticon"
          class="absolute left-0 top-20 sm:left-[250px] lg:left-[150px]" />
        <img src="/assets/images/banner-rightIcon.png" alt="banner-rightIcon"
          class="absolute -top-4 right-0 sm:right-[250px] lg:right-[150px]" />
        <div class="container">
          <div class="relative bg-[url('/assets/images/world-map.png')] bg-cover bg-top bg-no-repeat pb-0 lg:pb-60">
            <div class="relative z-[1] text-center text-white lg:w-3/5 ltr:lg:text-left rtl:lg:text-right xl:w-1/2">
              <h1 class="text-4xl font-extrabold leading-normal sm:text-5xl lg:text-[70px] lg:leading-[90px]">
                Аренда
                <span class="italic text-primary"> мультимедийного</span>
                <span class="italic text-secondary"> оборудования</span>
              </h1>
              <p class="my-8 text-lg lg:w-[90%]">
                Профессиональная консультация по подбору
              </p>
              <NuxtLink to="/#contacts" class="btn bg-cyan mx-auto mt-2 block w-fit text-white lg:mx-0 lg:rtl:ml-auto">
                Заказать</NuxtLink>
            </div>
            <div
              class="-top-4 bottom-0 mx-auto mb-2 mt-5 w-auto ltr:right-0 rtl:left-0 md:w-[540px] lg:absolute lg:mb-0 lg:mt-0 xl:w-[650px]"
              :data-aos="store.direction === 'rtl' ? 'fade-right' : 'fade-left'" data-aos-duration="1000"
              data-aos-offset="0" data-aos-delay="300">
              <img src="/assets/images/Retro_TV_Vector.svg" alt="home-banner-icon" class="rtl:rotate-y-180" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Equipments />

    <!-- агентство предлагает -->
    <section
      class="bg-[url(/assets/images/service-bg.png)] bg-cover bg-center bg-no-repeat py-14 dark:bg-none lg:py-[100px]">
      <div class="container">
        <!-- <div class="heading text-center">
          <h4>Агентство DarkEvent предлагает</h4>
        </div> -->
        <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div data-aos="fade-up" data-aos-duration="1000">
            <div
              class="group rounded-3xl border-2 border-white bg-white p-6 transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:border-white/10 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.04] dark:to-transparent dark:!shadow-none dark:hover:bg-secondary"
              style="box-shadow: -20px 30px 70px rgba(219, 222, 225, 0.4)">
              <div
                class="flex h-14 w-14 items-center justify-center rounded-full bg-secondary transition group-hover:bg-black"
                style="box-shadow: 0px 15px 30px rgba(180, 118, 229, 0.4)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.5"
                    d="M14.2371 18.7087L18.7103 14.2366L21.4893 17.0156V21.4888H17.0172L14.2371 18.7087ZM5.2917 9.76334L2.3092 6.7819C2.21118 6.68399 2.13342 6.56771 2.08036 6.43973C2.02731 6.31174 2 6.17455 2 6.03601C2 5.89746 2.02731 5.76027 2.08036 5.63229C2.13342 5.5043 2.21118 5.38803 2.3092 5.29012L5.2917 2.30867C5.4894 2.11103 5.75751 2 6.03706 2C6.31662 2 6.58472 2.11103 6.78242 2.30867L9.76598 5.29012L5.2917 9.76334Z"
                    fill="white" />
                  <path
                    d="M5.29177 12.7459L7.21895 14.6742L8.71179 13.1813L6.78249 11.2552L8.27321 9.76449L10.2015 11.6917L11.6922 10.201L9.76605 8.27271L11.2568 6.78199L13.1818 8.71128L14.6736 7.2195L12.7454 5.29232L15.7279 2.31087C15.9256 2.11323 16.1937 2.0022 16.4732 2.0022C16.7528 2.0022 17.0209 2.11323 17.2186 2.31087L21.6908 6.78304C21.8884 6.98074 21.9994 7.24885 21.9994 7.5284C21.9994 7.80795 21.8884 8.07606 21.6908 8.27376L8.27321 21.6913C8.07551 21.889 7.8074 22 7.52785 22C7.2483 22 6.98019 21.889 6.78249 21.6913L2.31032 17.2192C2.11268 17.0215 2.00165 16.7533 2.00165 16.4738C2.00165 16.1942 2.11268 15.9261 2.31032 15.7284L5.29177 12.7459Z"
                    fill="white" />
                </svg>
              </div>
              <NuxtLink to="/advantages/a-wide-range-of-equipment"
                class="my-8 inline-block text-[22px] font-extrabold text-black dark:text-white dark:group-hover:text-black">
                Широкий ассортимент
              </NuxtLink>
              <p class="mb-10 line-clamp-6 text-lg font-semibold transition dark:group-hover:text-black">
                Компания предлагает широкий выбор видео и аудио оборудования для
                любых мероприятий
              </p>
              <NuxtLink to="/advantages/a-wide-range-of-equipment"
                class="flex h-11 w-11 items-center justify-center rounded-full bg-[#F3F4F6] transition group-hover:bg-black rtl:rotate-180 dark:bg-gray-dark">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="text-black transition group-hover:text-white dark:text-white">
                  <path
                    d="M9.41083 14.4109L10.5892 15.5892L16.1783 10.0001L10.5892 4.41089L9.41083 5.58922L12.9883 9.16672H5V10.8334H12.9883L9.41083 14.4109Z"
                    fill="currentColor" />
                </svg>
              </NuxtLink>
            </div>
          </div>

          <div data-aos="fade-up" data-aos-duration="1000">
            <div
              class="group rounded-3xl border-2 border-white bg-white p-6 transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:border-white/10 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.04] dark:to-transparent dark:!shadow-none dark:hover:bg-secondary"
              style="box-shadow: -20px 30px 70px rgba(219, 222, 225, 0.4)">
              <div
                class="flex h-14 w-14 items-center justify-center rounded-full bg-secondary transition group-hover:bg-black"
                style="box-shadow: 0px 15px 30px rgba(180, 118, 229, 0.4)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.5"
                    d="M10.1658 20C10.5862 20 10.9617 19.7371 11.1054 19.342L15.8374 6.34204C16.0749 5.68981 15.5919 5 14.8978 5H13.8341C13.4137 5 13.0382 5.26292 12.8945 5.65796L8.16245 18.658C7.92504 19.3102 8.40804 20 9.10214 20H10.1658Z"
                    fill="white" />
                  <path
                    d="M17.9889 17.0964C18.3794 17.4871 19.0127 17.4873 19.4034 17.0966L23.2929 13.2071C23.6834 12.8166 23.6834 12.1834 23.2929 11.7929L19.4036 7.90359C19.0129 7.51287 18.3793 7.5131 17.9889 7.90409L17.2814 8.61254C16.8913 9.00328 16.8916 9.63632 17.2823 10.0266L19.0501 11.7927C19.441 12.1832 19.441 12.8168 19.0502 13.2074L17.2822 14.9746C16.8916 15.365 16.8914 15.9981 17.2818 16.3888L17.9889 17.0964ZM4.94994 13.2073C4.55904 12.8167 4.55896 12.1832 4.94977 11.7925L6.71781 10.0253C7.10839 9.63494 7.10858 9.00184 6.71823 8.61121L6.01111 7.90359C5.62062 7.51283 4.98726 7.51272 4.59664 7.90334L0.707106 11.7929C0.316582 12.1834 0.316582 12.8166 0.707107 13.2071L4.59639 17.0964C4.98711 17.4871 5.62066 17.4869 6.01111 17.0959L6.71856 16.3874C7.10874 15.9967 7.10837 15.3637 6.71773 14.9734L4.94994 13.2073Z"
                    fill="white" />
                </svg>
              </div>
              <NuxtLink to="/advantages/high-quality"
                class="my-8 inline-block text-[22px] font-extrabold text-black dark:text-white dark:group-hover:text-black">
                Высокое качество
              </NuxtLink>
              <p class="mb-10 line-clamp-6 text-lg font-semibold transition dark:group-hover:text-black">
                Мы используем только высококачественное оборудование от ведущих
                производителей, что гарантирует отличное качество звука и
                изображения
              </p>
              <NuxtLink to="/advantages/high-quality"
                class="flex h-11 w-11 items-center justify-center rounded-full bg-[#F3F4F6] transition group-hover:bg-black rtl:rotate-180 dark:bg-gray-dark">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="text-black transition group-hover:text-white dark:text-white">
                  <path
                    d="M9.41083 14.4109L10.5892 15.5892L16.1783 10.0001L10.5892 4.41089L9.41083 5.58922L12.9883 9.16672H5V10.8334H12.9883L9.41083 14.4109Z"
                    fill="currentColor" />
                </svg>
              </NuxtLink>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <div
              class="group rounded-3xl border-2 border-white bg-white p-6 transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:border-white/10 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.04] dark:to-transparent dark:!shadow-none dark:hover:bg-secondary"
              style="box-shadow: -20px 30px 70px rgba(219, 222, 225, 0.4)">
              <div
                class="flex h-14 w-14 items-center justify-center rounded-full bg-secondary transition group-hover:bg-black"
                style="box-shadow: 0px 15px 30px rgba(180, 118, 229, 0.4)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.5"
                    d="M6.21053 20H3.05263C2.77346 20 2.50572 19.8891 2.30831 19.6917C2.1109 19.4943 2 19.2265 2 18.9474V10.5263C2 10.2471 2.1109 9.97941 2.30831 9.782C2.50572 9.58459 2.77346 9.47369 3.05263 9.47369H6.21053C6.4897 9.47369 6.75744 9.58459 6.95485 9.782C7.15226 9.97941 7.26316 10.2471 7.26316 10.5263V18.9474C7.26316 19.2265 7.15226 19.4943 6.95485 19.6917C6.75744 19.8891 6.4897 20 6.21053 20ZM20.9474 20H17.7895C17.5103 20 17.2426 19.8891 17.0452 19.6917C16.8477 19.4943 16.7368 19.2265 16.7368 18.9474V7.36843C16.7368 7.08925 16.8477 6.82151 17.0452 6.6241C17.2426 6.4267 17.5103 6.3158 17.7895 6.3158H20.9474C21.2265 6.3158 21.4943 6.4267 21.6917 6.6241C21.8891 6.82151 22 7.08925 22 7.36843V18.9474C22 19.2265 21.8891 19.4943 21.6917 19.6917C21.4943 19.8891 21.2265 20 20.9474 20Z"
                    fill="white" />
                  <path
                    d="M13.5789 20H10.421C10.1419 20 9.87412 19.8891 9.67672 19.6917C9.47931 19.4943 9.36841 19.2265 9.36841 18.9474V1.05263C9.36841 0.773456 9.47931 0.505715 9.67672 0.308309C9.87412 0.110902 10.1419 0 10.421 0H13.5789C13.8581 0 14.1259 0.110902 14.3233 0.308309C14.5207 0.505715 14.6316 0.773456 14.6316 1.05263V18.9474C14.6316 19.2265 14.5207 19.4943 14.3233 19.6917C14.1259 19.8891 13.8581 20 13.5789 20Z"
                    fill="white" />
                </svg>
              </div>
              <NuxtLink to="/advantages/professional-approach"
                class="my-8 inline-block text-[22px] font-extrabold text-black dark:text-white dark:group-hover:text-black">
                Профессиональный подход
              </NuxtLink>
              <p class="mb-10 line-clamp-6 text-lg font-semibold transition dark:group-hover:text-black">
                Наши специалисты помогут подобрать необходимое оборудование и
                произведут монтаж и демонтаж
              </p>
              <NuxtLink to="/advantages/professional-approach"
                class="flex h-11 w-11 items-center justify-center rounded-full bg-[#F3F4F6] transition group-hover:bg-black rtl:rotate-180 dark:bg-gray-dark">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="text-black transition group-hover:text-white dark:text-white">
                  <path
                    d="M9.41083 14.4109L10.5892 15.5892L16.1783 10.0001L10.5892 4.41089L9.41083 5.58922L12.9883 9.16672H5V10.8334H12.9883L9.41083 14.4109Z"
                    fill="currentColor" />
                </svg>
              </NuxtLink>
            </div>
          </div>
        </div>


      </div>
      <div
        class="relative my-14 mx-auto border-[10px] border-transparent bg-black py-14 dark:border-black dark:bg-gray-dark lg:my-[100px] lg:py-[100px] xl:max-w-[1440px] xl:rounded-3xl">
        <div class="container">
          <div class="items-end gap-4 lg:flex xl:gap-0">
            <div class="lg:w-full">
              <div class="heading mb-16 text-center ltr:lg:text-left rtl:lg:text-right">
                <!-- <h1 class="leading-normal !text-white lg:!leading-[50px]">Мультимедийное оборудование для проведения
                  мероприятий
                </h1> -->
                <p class="line-clamp-6 text-lg font-semibold transition dark:group-hover:text-black mt-3">В области аренды
                  мультимедийного оборудования мы работаем не первый год, и успели за это время многое –
                  приобрести постоянных заказчиков, завоевать безукоризненную репутацию и занять лидирующую ступеньку
                  среди множества компаний Москвы. Вам предстоит провести выставочное, частное или даже деловое
                  мероприятие? Мы немедленно придем на помощь! Обращайтесь, заказывайте аренду оборудование для
                  мероприятий и убедитесь, насколько профессионально действует наша команда.
                </p>
              </div>
              <div class="grid gap-x-7 gap-y-12 sm:grid-cols-2 aos-init aos-animate" data-aos="fade-right"
                data-aos-duration="1000">
                <div class="flex transition">
                  <div>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_36_337)">
                        <path opacity="0.5"
                          d="M19.2867 23.9285V25.119C19.2867 25.7505 19.0358 26.3561 18.5893 26.8027C18.1427 27.2492 17.5371 27.5001 16.9056 27.5001H12.1435C11.512 27.5001 10.9063 27.2492 10.4598 26.8027C10.0133 26.3561 9.76239 25.7505 9.76239 25.119V23.9285H19.2867Z"
                          fill="#B476E5"></path>
                        <path
                          d="M9.69213 21.5473C9.33855 20.0318 7.74323 18.7924 7.08844 17.9757C5.96718 16.5748 5.26448 14.8857 5.06127 13.1028C4.85807 11.32 5.16263 9.51606 5.93988 7.89876C6.71712 6.28146 7.93542 4.91663 9.45445 3.96148C10.9735 3.00632 12.7314 2.49971 14.5258 2.5C16.3202 2.50029 18.078 3.00746 19.5967 3.9631C21.1154 4.91874 22.3333 6.28396 23.11 7.90151C23.8867 9.51906 24.1907 11.3231 23.9869 13.1059C23.7831 14.8886 23.0799 16.5776 21.9582 17.9781C21.3034 18.7936 19.7105 20.033 19.3569 21.5473H9.69094H9.69213ZM15.715 12.029V7.26094L10.3576 14.4101H13.334V19.1722L18.6914 12.029H15.715Z"
                          fill="#B476E5"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_36_337">
                          <rect width="30" height="30" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="ltr:pl-3 rtl:pr-3 -mt-8">
                    <h4 class="mb-2 text-lg font-bold text-white">Работаем индивидуально</h4>
                    <p class="font-semibold">Для нас не имеет значения, проведение какого именно мероприятия вам предстоит
                      – мы вместе с вами подберем идеальный вариант. Мы предоставим мультимедийное оборудование для
                      качественного воспроизведения видео или аудио. Гарантируем, что ваше мероприятие заиграет новыми
                      уникальными красками.</p>
                  </div>
                </div>
                <div class="flex transition">
                  <div>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_36_392)">
                        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                          d="M14.2364 27.9997V24.9966C12.4567 25.1078 11.1218 23.6617 11.1218 21.9935C11.1218 20.3249 12.4567 18.9904 14.2364 19.1015V16.0984H8.67477C8.89731 15.6535 9.3422 15.0973 9.3422 14.5412C9.3422 12.3167 6.00534 12.3167 6.00534 14.5412C6.00534 15.0975 6.33905 15.6535 6.56161 16.0984H1V27.1102C1 27.5551 1.44487 27.9999 1.88974 27.9999H14.2365L14.2364 27.9997Z"
                          fill="#B476E5"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M28.6959 16.0981H25.2477C25.3588 17.8778 24.0242 19.3238 22.3557 19.3238C20.576 19.3238 19.2412 17.8778 19.3526 16.0981H15.4595V20.7698C15.0146 20.6586 14.4584 20.3249 14.0135 20.3249C11.9001 20.3249 11.9001 23.6618 14.0135 23.6618C14.4584 23.6618 15.0146 23.4392 15.4595 23.2169V27.9997H27.8063C28.2511 27.9997 28.696 27.5549 28.696 27.11V16.0982L28.6959 16.0981Z"
                          fill="#B476E5"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M28.6731 14.9015V3.88974C28.6731 3.44487 28.2283 3 27.672 3H15.4367V5.89196C17.1052 5.66942 18.6624 7.00423 18.6624 8.78392C18.6624 10.5636 17.1052 11.8985 15.4367 11.6759V14.9016H20.9983C20.8871 15.3464 20.5534 15.9027 20.5534 16.3476C20.5534 18.5722 24.0017 18.5722 24.0017 16.3476C24.0017 15.9027 23.668 15.3465 23.4454 14.9016L28.6731 14.9015Z"
                          fill="#B476E5"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_36_392">
                          <rect width="30" height="30" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="ltr:pl-3 rtl:pr-3 -mt-8">
                    <h4 class="mb-2 text-lg font-bold text-white">Сотрудничаем только с известными компаниями</h4>
                    <p class="font-semibold">Наше оборудование высокого класса – мы гарантируем, что вам не придется
                      столкнуться с любыми техническими недочетами.
                    </p>
                  </div>
                </div>
                <div class="flex transition">
                  <div><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_36_401)">
                        <path opacity="0.5"
                          d="M9.68219 19.8007L10.8868 17.8733C11.9699 18.5518 13.2226 18.9106 14.5006 18.9086C15.7787 18.9106 17.0313 18.5518 18.1144 17.8733L19.319 19.8007C17.8749 20.7054 16.2047 21.184 14.5006 21.1814C12.7965 21.184 11.1263 20.7054 9.68219 19.8007Z"
                          fill="#B476E5"></path>
                        <path
                          d="M24.7284 10.9537C25.3312 10.9537 25.9093 11.1932 26.3355 11.6194C26.7618 12.0457 27.0012 12.6238 27.0012 13.2265V17.7722C27.0012 18.375 26.7618 18.9531 26.3355 19.3794C25.9093 19.8056 25.3312 20.0451 24.7284 20.0451H23.5215C23.2445 22.2421 22.1752 24.2625 20.5143 25.7272C18.8534 27.1918 16.715 28 14.5006 28V25.7272C16.309 25.7272 18.0433 25.0088 19.322 23.7301C20.6007 22.4513 21.3191 20.717 21.3191 18.9086V12.0901C21.3191 10.2817 20.6007 8.54743 19.322 7.26871C18.0433 5.98999 16.309 5.27162 14.5006 5.27162C12.6922 5.27162 10.9579 5.98999 9.67919 7.26871C8.40047 8.54743 7.6821 10.2817 7.6821 12.0901V20.0451H4.27284C3.67004 20.0451 3.09194 19.8056 2.6657 19.3794C2.23946 18.9531 2 18.375 2 17.7722V13.2265C2 12.6238 2.23946 12.0457 2.6657 11.6194C3.09194 11.1932 3.67004 10.9537 4.27284 10.9537H5.47972C5.75704 8.75689 6.82646 6.73677 8.48733 5.27238C10.1482 3.808 12.2864 3 14.5006 3C16.7149 3 18.853 3.808 20.5139 5.27238C22.1748 6.73677 23.2442 8.75689 23.5215 10.9537H24.7284Z"
                          fill="#B476E5"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_36_401">
                          <rect width="30" height="30" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg></div>
                  <div class="ltr:pl-3 rtl:pr-3 -mt-8">
                    <h4 class="mb-2 text-lg font-bold text-white">Обеспечиваем комплексное обслуживание</h4>
                    <p class="font-semibold">Для проведения мероприятия мы не только предлагаем прокат оборудования, но и
                      обеспечим выезд специалиста на место для согласования всех технических нюансов.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="top-1/2 mx-auto mt-10 ltr:right-5 rtl:left-5 md:w-[45%] lg:mt-0 xl:absolute xl:-translate-y-1/2">
              <img src="/assets/images/box-img.png" alt="box-img" class="w-full rtl:rotate-y-180">
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <!-- <section
      class="bg-gradient-to-t from-transparent to-white/[55%] pt-10 dark:bg-gradient-to-b dark:from-white/5 dark:to-transparent lg:py-20">
      <div class="container">
        <div class="flex flex-col items-center gap-12 lg:flex-row lg:gap-28">
          <div class="order-2 lg:order-1">
            <div class="heading mb-0 text-center ltr:sm:text-left rtl:sm:text-right">
              <h6 class="!text-secondary">Our marketing agency</h6>
              <h4>Business who achieved pro results</h4>
            </div>
            <p class="mt-[18px] text-lg font-semibold">
              We admire that business to pro level which is highly impacts on growth revenues and marketing strength.
            </p>
            <div class="text-black">
              <div class="mt-14 flex items-center gap-4">
                <div class="text-primary">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 2.5C12.5277 2.5 10.111 3.23311 8.05538 4.60663C5.99976 5.98015 4.39761 7.93238 3.45151 10.2165C2.50542 12.5005 2.25787 15.0139 2.74019 17.4386C3.2225 19.8634 4.41301 22.0907 6.16117 23.8388C7.90933 25.587 10.1366 26.7775 12.5614 27.2598C14.9861 27.7421 17.4995 27.4946 19.7836 26.5485C22.0676 25.6024 24.0199 24.0002 25.3934 21.9446C26.7669 19.889 27.5 17.4723 27.5 15C27.5 13.3585 27.1767 11.733 26.5485 10.2165C25.9203 8.69989 24.9996 7.3219 23.8388 6.16117C22.6781 5.00043 21.3001 4.07969 19.7836 3.45151C18.267 2.82332 16.6415 2.5 15 2.5ZM20.8875 12.1375L13.3875 19.6375C13.2713 19.7547 13.1331 19.8477 12.9807 19.9111C12.8284 19.9746 12.665 20.0072 12.5 20.0072C12.335 20.0072 12.1716 19.9746 12.0193 19.9111C11.867 19.8477 11.7287 19.7547 11.6125 19.6375L9.11251 17.1375C8.99596 17.021 8.90351 16.8826 8.84043 16.7303C8.77736 16.578 8.74489 16.4148 8.74489 16.25C8.74489 16.0852 8.77736 15.922 8.84043 15.7697C8.90351 15.6174 8.99596 15.479 9.11251 15.3625C9.22905 15.246 9.36742 15.1535 9.51969 15.0904C9.67197 15.0273 9.83518 14.9949 10 14.9949C10.1648 14.9949 10.328 15.0273 10.4803 15.0904C10.6326 15.1535 10.771 15.246 10.8875 15.3625L12.5 16.9875L19.1125 10.3625C19.3479 10.1271 19.6671 9.99489 20 9.99489C20.3329 9.99489 20.6521 10.1271 20.8875 10.3625C21.1229 10.5979 21.2551 10.9171 21.2551 11.25C21.2551 11.5829 21.1229 11.9021 20.8875 12.1375Z"
                      fill="currentcolor" />
                  </svg>
                </div>
                <div>
                  <h5 class="text-lg font-semibold dark:text-white">Corporate Financial advisory</h5>
                </div>
              </div>
              <div class="mt-6 flex items-center gap-4">
                <div class="text-primary">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 2.5C12.5277 2.5 10.111 3.23311 8.05538 4.60663C5.99976 5.98015 4.39761 7.93238 3.45151 10.2165C2.50542 12.5005 2.25787 15.0139 2.74019 17.4386C3.2225 19.8634 4.41301 22.0907 6.16117 23.8388C7.90933 25.587 10.1366 26.7775 12.5614 27.2598C14.9861 27.7421 17.4995 27.4946 19.7836 26.5485C22.0676 25.6024 24.0199 24.0002 25.3934 21.9446C26.7669 19.889 27.5 17.4723 27.5 15C27.5 13.3585 27.1767 11.733 26.5485 10.2165C25.9203 8.69989 24.9996 7.3219 23.8388 6.16117C22.6781 5.00043 21.3001 4.07969 19.7836 3.45151C18.267 2.82332 16.6415 2.5 15 2.5ZM20.8875 12.1375L13.3875 19.6375C13.2713 19.7547 13.1331 19.8477 12.9807 19.9111C12.8284 19.9746 12.665 20.0072 12.5 20.0072C12.335 20.0072 12.1716 19.9746 12.0193 19.9111C11.867 19.8477 11.7287 19.7547 11.6125 19.6375L9.11251 17.1375C8.99596 17.021 8.90351 16.8826 8.84043 16.7303C8.77736 16.578 8.74489 16.4148 8.74489 16.25C8.74489 16.0852 8.77736 15.922 8.84043 15.7697C8.90351 15.6174 8.99596 15.479 9.11251 15.3625C9.22905 15.246 9.36742 15.1535 9.51969 15.0904C9.67197 15.0273 9.83518 14.9949 10 14.9949C10.1648 14.9949 10.328 15.0273 10.4803 15.0904C10.6326 15.1535 10.771 15.246 10.8875 15.3625L12.5 16.9875L19.1125 10.3625C19.3479 10.1271 19.6671 9.99489 20 9.99489C20.3329 9.99489 20.6521 10.1271 20.8875 10.3625C21.1229 10.5979 21.2551 10.9171 21.2551 11.25C21.2551 11.5829 21.1229 11.9021 20.8875 12.1375Z"
                      fill="currentcolor" />
                  </svg>
                </div>
                <div>
                  <h5 class="text-lg font-semibold dark:text-white">High growth revenues in marketing service.</h5>
                </div>
              </div>
              <div class="mt-6 flex items-center gap-4">
                <div class="text-primary">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 2.5C12.5277 2.5 10.111 3.23311 8.05538 4.60663C5.99976 5.98015 4.39761 7.93238 3.45151 10.2165C2.50542 12.5005 2.25787 15.0139 2.74019 17.4386C3.2225 19.8634 4.41301 22.0907 6.16117 23.8388C7.90933 25.587 10.1366 26.7775 12.5614 27.2598C14.9861 27.7421 17.4995 27.4946 19.7836 26.5485C22.0676 25.6024 24.0199 24.0002 25.3934 21.9446C26.7669 19.889 27.5 17.4723 27.5 15C27.5 13.3585 27.1767 11.733 26.5485 10.2165C25.9203 8.69989 24.9996 7.3219 23.8388 6.16117C22.6781 5.00043 21.3001 4.07969 19.7836 3.45151C18.267 2.82332 16.6415 2.5 15 2.5ZM20.8875 12.1375L13.3875 19.6375C13.2713 19.7547 13.1331 19.8477 12.9807 19.9111C12.8284 19.9746 12.665 20.0072 12.5 20.0072C12.335 20.0072 12.1716 19.9746 12.0193 19.9111C11.867 19.8477 11.7287 19.7547 11.6125 19.6375L9.11251 17.1375C8.99596 17.021 8.90351 16.8826 8.84043 16.7303C8.77736 16.578 8.74489 16.4148 8.74489 16.25C8.74489 16.0852 8.77736 15.922 8.84043 15.7697C8.90351 15.6174 8.99596 15.479 9.11251 15.3625C9.22905 15.246 9.36742 15.1535 9.51969 15.0904C9.67197 15.0273 9.83518 14.9949 10 14.9949C10.1648 14.9949 10.328 15.0273 10.4803 15.0904C10.6326 15.1535 10.771 15.246 10.8875 15.3625L12.5 16.9875L19.1125 10.3625C19.3479 10.1271 19.6671 9.99489 20 9.99489C20.3329 9.99489 20.6521 10.1271 20.8875 10.3625C21.1229 10.5979 21.2551 10.9171 21.2551 11.25C21.2551 11.5829 21.1229 11.9021 20.8875 12.1375Z"
                      fill="currentcolor" />
                  </svg>
                </div>
                <div>
                  <h5 class="text-lg font-semibold dark:text-white">Get full contact support from our community.</h5>
                </div>
              </div>
            </div>
            <button type="button" class="btn mt-10 bg-secondary py-5 px-[50px] text-white hover:bg-primary">Know
              more</button>
          </div>
          <div class="order-1 w-full max-w-[560px] lg:order-2">
            <img src="/assets/images/marketing/marketing3.png" class="rtl:rotate-y-180" alt="" />
          </div>
        </div>
      </div>
    </section> -->

    <section class="py-14 lg:py-[100px]">
      <faq></faq>
    </section>

    <proud-of></proud-of>

    <stages-of-work></stages-of-work>

    <!-- <Testimonial class="py-14 lg:py-[100px]"></Testimonial> -->


    <ContactsSent v-if="store.contactsSent"></ContactsSent>
    <Contacts v-if="!store.contactsSent"></Contacts>

  </div>
</template>
<script setup>
import { useAppStore } from "@/stores/index";
const store = useAppStore();

useHead({
  title: 'Аренда мультимедийного оборудования для проведения мероприятий в Москве',
  meta: [{
    name: 'description',
    content: 'Прокат мультимедийного оборудования, аренда звукового, видео оборудования для мероприятий с доставкой и настройкой по Москве'
  }]
})

defineProps({
  showTitle: {
    type: Boolean,
    default: true,
  },
});
</script>
