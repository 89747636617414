<template>
  <div class="project">
    <div
      class="relative rounded-3xl border border-transparent bg-white drop-shadow-[5px_10px_80px_rgba(119,128,161,0.15)] transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:bg-gray-dark dark:drop-shadow-none"
    >
      <NuxtLink
        :to="{ name: 'equipments-slug', params: { slug: attributes.slug } }"
        class="absolute left-0 top-0 h-full w-full"
      />
      <!-- <img :src="`${strapiUrl}${imgUrl}`" :alt="attributes.seo.title"
        class="h-52 w-full rounded-t-3xl object-cover bg-white" /> -->
      <NuxtLink
        :to="{ name: 'equipments-slug', params: { slug: attributes.slug } }"
      >
        <NuxtImg
          :src="`${strapiUrl}${imgUrl}`"
          :alt="attributes.seo.title"
          preset="cover"
          class="h-52 w-full rounded-[32px] rounded-t-3xl bg-white object-cover"
        />
      </NuxtLink>

      <div class="p-5 text-sm font-bold">
        <h6 class="mb-1 text-black dark:text-white">{{ attributes.name }}</h6>
      </div>
    </div>
  </div>
</template>

<script setup>
const strapiUrl = useRuntimeConfig().public.strapi.url

const props = defineProps({
  category: {
    type: Object,
    required: true,
  },
})

const attributes = props.category.attributes
const imgUrl = attributes.main_image.data.attributes.url
</script>
